/* Estilos generales */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  background-color: #0070da;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #005bb5;
}

.pagination button.active {
  background-color: #005bb5;
  font-weight: bold;
}

.filter-options {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
}

.filter-options button {
  padding: 10px 20px;
  background-color: #0070da;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-options button:hover {
  background-color: #005bb5;
}

/* Clase para el escudo del equipo */
.equipo-escudo {
  width: 40px;
  height: 40px;
}

.jugador-foto {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #ddd;
}

/* Contenedor de la liga */
.liga-container {
  min-height: 100vh;
  padding: 20px;
}

/* Navbar y estilos generales */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.navbar-logo img {
  height: 100px;
  width: auto;
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.navbar-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  margin-left: 30px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: yellow;
}

/* Estilos para la versión responsive */
.navbar-toggle {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
}

@media screen and (max-width: 768px) {
  .jugador-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
  }

  .jugador-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .jugador-info p {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .navbar {
    justify-content: space-between;
    padding: 20px;
  }

  .content img {
    max-width: 100%;
    height: auto;
  }

  .navbar-logo img {
    height: 45px;
  }

  .navbar-links {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 1;
    width: 100%;
    padding: 10px 0;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
  }

  .navbar-links li {
    margin: 15px 0;
  }

  .navbar-toggle {
    display: block;
  }

  .jugadores-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
}

/* Estilos para la versión de escritorio */
@media screen and (min-width: 768px) {
  .jugadores-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
  }

  .jugador-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
  }

  .jugador-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .jugador-info p {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}
