/* Estilos generales */
@font-face {
  font-family: 'DINPro';
  src: url('./fonts/DINPro-Bold.ttf') format('truetype');
  font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'DINPro', Arial, sans-serif;
  background-color: #0055a4;
  color: #fff; /* Texto blanco por defecto */
  background-size: cover;
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

/* Header */
.header {
  background: transparent; /* Transparente porque el fondo ya es azul */
  color: #fff;
  padding: 50px 20px;
  border-radius: 8px;
}

.title {
  font-size: 3rem;
  margin: 0;
  color: #ffcd00;
}

.subtitle {
  font-size: 1.5rem;
  margin: 10px 0 20px;
}

.header-buttons {
  margin-bottom: 10px;
}

.cta-button {
  padding: 10px 20px;
  font-family: 'DINPro', Arial, sans-serif;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #ffcd00;
  color: #003366;
}

.cta-button:hover {
  background: #e6b800;
}

.login-link {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #fff;
}

.login-link a {
  color: #ffcd00;
  text-decoration: none;
  font-weight: bold;
}

.login-link a:hover {
  text-decoration: underline;
}

/* Sección de características */
.features {
  margin: 25px 0;
}

.features h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffcd00; /* Título destacado en amarillo */
}

.features p {
  font-size: 1rem;
  max-width: 600px;
  margin: 0 auto 30px;
  color: #fff;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%; /* Asegurarse de que el contenedor no se desborde */
  padding: 0 10px; /* Añadir algo de espacio interno */
}

.card {
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #333;
  margin: 0 auto; /* Centrar las tarjetas */
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

.card .icon {
  font-size: 3rem;
  margin-bottom: 15px;
  color: #ffcd00;
}

.card h3 {
  font-size: 1.5rem;
  color: #0055a4;
}

.card p {
  font-size: 1rem;
  color: #666;
}

/* Footer */
.footer {
  margin-top: 50px;
  font-size: 0.9rem;
}

.footer p {
  color: #fff; /* Texto blanco */
}

.social-links {
  margin-top: 10px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-links a {
  text-decoration: none;
  color: #fff; /* Enlaces en amarillo */
  font-weight: bold;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #ffcd00;
}

/* Responsividad */
@media (max-width: 768px) {
  .cards {
    flex-direction: column; /* Las tarjetas se apilan verticalmente */
    gap: 10px; /* Reducir el espacio entre las tarjetas */
  }

  .card {
    width: 90%; /* Ajustar el ancho de las tarjetas para evitar desbordamiento */
  }
}
