/* Estilos generales */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  padding: 20px;
}

.liga-container {
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.navbar-logo img {
  height: 100px;
  width: auto;
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.navbar-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  margin-left: 30px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: yellow;
}

/* Estilos para la versión responsive */
.navbar-toggle {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
}

@media screen and (max-width: 768px) {
  .navbar {
    justify-content: space-between;
    padding: 20px;
  }

  .content img {
    max-width: 100%; /* La imagen nunca será más ancha que el contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
  }

  .navbar-logo img {
    height: 45px;
  }

  .navbar-links {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    flex-direction: column; /* Cambiado a columna */
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 1;
    background-color: #0070da; /* Fondo para el menú */
    width: 100%; /* Ancho completo */
  }

  .navbar-links a {
    color: white;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links li {
    margin: 15px 0; /* Espaciado vertical */
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
  }
}
