/* Logo styling */
@font-face {
  font-family: 'DINPro';
  src: url('./fonts/DINPro-Bold.ttf') format('truetype');
  font-weight: bold;
}

body {
  font-family: 'DINPro', Arial, sans-serif;
}

.register-logo {
    display: block;
    max-width: 223px;  /* Tamaño máximo del logo */
    margin: 0 auto 20px; /* Centrado horizontal y espacio abajo */
  }
  
  /* Otros estilos del formulario */
  .register-container {
    max-width: 450px;
    width: 100%;
    padding: 40px;
    transition: all 0.3s ease;
    margin-top: 20px;
  }
  
  h1 {
    text-align: center;
    color: #f7f7f7;
    margin-bottom: 25px;
    font-size: 1.8rem;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #f7f7f7;
  }
  
  input {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f7f7f7;
    transition: border-color 0.3s ease;
  }
  
  input:focus {
    border-color: #007bff;
    background-color: #fff;
    outline: none;
  }
  
  .password-container {
    position: relative;
  }
  
  .toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  
  .error {
    color: #e74c3c;
    font-size: 12px;
    margin-top: 5px;
  }
  
  @media (max-width: 600px) {
    .register-container {
      padding: 20px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  }
  