@font-face {
  font-family: "DINPro";
  src: url("./fonts/DINPro-Bold.ttf") format("truetype");
  font-weight: bold;
}

body {
  font-family: "DINPro", Arial, sans-serif;
}

.dashboard-container a {
  color: white;
}

.dashboard-container {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.user-info {
  margin-top: 20px;
}

.user-info p {
  font-size: 16px;
  margin: 5px 0;
}

.dashboard-actions {
  margin-top: 20px;
}

button {
  margin-right: 10px;
  font-family: "DINPro", Arial, sans-serif;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #032a55;
}

.leagues-section {
  margin-top: 20px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.logout-button {
  margin-top: 20px;
  background-color: #ff4c4c;
  color: white;
  border: none;
}

@media (max-width: 600px) {
  .buttons-container {
    flex-direction: column;
  }

  button {
    margin-bottom: 10px;
  }
}
